<template>
  <div>   
    <v-dialog v-model="SuiteTemplateDialog" persistent max-width="500">
      <v-card tile v-if="SuiteTemplateDialog"
          class="mx-auto"
          max-width="500"
        >
          <v-img :height="ShowSiteCost ? 220 : 180" contain class="black" :src="require('@/assets/logo.png')">
          <v-card style="background-color: #110022bb;" tile height="100%" width="100%">
                  <v-card-title style="position: relative;"
            class="mediumoverline white--text">
            <v-avatar 
                color="purple"
                class="subheading white--text"
                size="40"
                v-text="NewSuiteStep"
              ></v-avatar>
              <span v-if="NewSuiteStep !== 3">
                {{ currentNSTitle }} 
              </span>
              <div v-if="NewSuiteStep === 3" style="position: absolute;left: 70px;display: inline-flex;height: 45px;" dense class="">
                {{ currentNSTitle }}
                <v-avatar style="margin-left: 5px;" size="35" class="lime">
                <v-icon dark>mdi-plus
                </v-icon>
                </v-avatar>
                </div>
              <div v-if="!$vuetify.breakpoint.xs && NewSuiteStep === 3" class="" style="margin-left: 15px;margin-top:20px;display: flex; flex-wrap: wrap;flex: 1 1 0%;align-items: center;height: 0px;color: lime;">
                    <!-- <div >&</div> -->
                    <div >{{ComputedScopeOptions[scopestep].DisplayName}}</div>
                </div>
            <v-card-title v-if="$vuetify.breakpoint.xs && NewSuiteStep === 3" style="align-items: center;height: 100%;z-index: 2;padding: 0px;color: lime;">
                  <!-- <div >&</div> -->
                    <div >{{ComputedScopeOptions[scopestep].DisplayName}}</div>
            </v-card-title>
              <v-spacer>
              </v-spacer>
              
              <v-btn fab small icon @click="CancelSaveSuiteTemplate()" dark outlined color="red">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title> 
            <v-card-text class="white--text" style="padding :20px;">
              <div v-html="currentNSDescription"/>
            </v-card-text>
            <!-- <v-card-title  class="lime" style="padding-top: 0px;padding-bottom: 50px;"> -->
             <v-list-item style="position: absolute; width: 100%; bottom: 0px;" class="lime" v-if="ShowSiteCost">
                <v-list-item-content v-if="!UpdatingPluginList">
                    <v-list-item-title>                                            
                            Estimate
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Estimated Monhtly cost or plugins selected
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action-text v-if="!UpdatingPluginList">
                    <v-list-item-title>                                            
                        {{CurrencyFormatter(NewSuitePlusTotal,$store.state.DefaultCurrency.Currency)}}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      per Month
                    </v-list-item-subtitle>
                </v-list-item-action-text>
            </v-list-item>
            <!-- </v-card-title> -->
            </v-card>
          </v-img>
    <v-window v-model="NewSuiteStep">
      
    <v-window-item :value="1">
      <v-card-text> 
        <v-select outlined item-text="Name" v-model="NewSuiteTemplate.OrganisationType"
          :items="SiteOrgTypeField.Options" label="Organisation Type"
        />
        <v-select outlined v-model="NewSuiteTemplate.OrgProfileType" label="Profile"
          :items="['Products','Services','Products & Services']"
        />
        <v-select outlined v-model="NewSuiteTemplate.ClientsType" label="Clients"
          :items="['B2B','B2C','B2X']"
        />
      </v-card-text>
    </v-window-item>
      <v-window-item :value="2">
      <v-card-text> 
      </v-card-text>
      <v-card-text>
      <v-select outlined v-model="NewSuiteTemplate.SiteApplication"
      @change="ConfigureApplicationOption(NewSuiteTemplate.SiteApplication)"
        label="Application" :items="['Site/Listing Only','Site/Listing PLUS','Skip Listing']"
      />
      <!-- 'Listing Only','Listing & Website','Site Only (No Listing)' -->
      <v-checkbox v-if="NewSuiteTemplate.SiteApplication" :label="WebsiteOptionLabel" 
        v-model="NewSuiteTemplate.CustomDomain" 
      />
      </v-card-text>
    </v-window-item>
    <v-window-item :value="3">
     
      <!-- value 3 PLUS options (team app, Team Portal, Client Portal etc.) -->
      <!-- Let's start with "for clients" and "for staff"?
      Exception being "Hobbyist or Content Creator" so yeah, right? -->
      <v-card-text>  
        <v-card
        flat
        tile
      >
    <v-window
      v-model="scopestep"
      
    >
      <v-window-item
        v-for="plus in ComputedScopeOptions"
          :key="plus.itemObjKey"
      >
        <v-card
          color="grey lighten-3"
          height="100%"
        >
        <v-card-text >
          <v-carousel v-model="scopeoptstep" >
            <v-carousel-item
              v-for="option in plus.Options"
                :key="option.itemObjKey"
            >
              <v-sheet
                height="100%"
                tile
              >
              <v-card-text style="padding: 40px;padding-top:0px;">
                 <SignupPackages style="padding:15px;" :CanToggleKeyPoints="false"
                 @UpdateKeyPointSelection="UpdateKeyPointSelection"
                @SignupwithPackage="SelectSiteScopeOption" :SignUpLabel="'Select'" :FullWidth="true"
                :System="System" :SystemEntities="SystemEntities" :SubscriptionPackages="[option.Package]"/>
              </v-card-text>
              </v-sheet>
            </v-carousel-item>
          </v-carousel>
           <!-- <v-expansion-panels>
          <v-expansion-panel v-for="option in plus.Options"
                :key="option.itemObjKey">
                <v-expansion-panel-header>
                  <v-list-item dense>
                      <v-list-item-action>
                        <v-icon left>{{option.Icon}}</v-icon>
                      </v-list-item-action>
                      <v-list-item-content>
                          {{option.DisplayName}}
                      </v-list-item-content>
                    </v-list-item>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card-text v-if="option.Description">
                  <div v-html="option.Description">
                  </div>
                  </v-card-text>
                  <v-switch dense v-model="NewSuiteTemplate.PLUSOptions[option.Name]"
              @click="CheckScopeOptionSelection(plus,option)"
                
                :label="'Active'"
              >
              </v-switch>
                  <v-card-text v-if="option.PluginOptions && NewSuiteTemplate.PLUSOptions[option.Name]">
                    <v-list-item v-for="opt in option.PluginOptions" :key="opt.itemObjKey">
                     <v-tooltip  
                    top max-width="300"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs"
                        v-on="on">
                        <v-list-item-title>
                          <v-icon left>
                            {{opt.Icon}}
                          </v-icon>
                          {{opt.Name}}
                        </v-list-item-title>
                        </v-list-item-content>
                         <v-list-item-action><v-switch dense v-model="NewSuiteTemplate.PLUSOptions[opt.id]"
                    @click="CheckScopeOptionSelectionOptions(plus,option,opt)"
            
                    ></v-switch>
                        </v-list-item-action>
                    </template>
                    <span v-html="opt.Description"> 
                    </span>
                </v-tooltip>
                    </v-list-item>
                  </v-card-text>
                </v-expansion-panel-content>
          </v-expansion-panel>
           </v-expansion-panels> -->
        </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>

    <v-card-actions class="justify-space-between">
      <v-btn
        text
        @click="prev"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-item-group
        v-model="scopestep"
        class="text-center"
        mandatory
      >
        <v-item
          v-for="plus in ComputedScopeOptions"
          :key="plus.itemObjKey"
          v-slot="{ active, toggle }"
        >
          <v-btn
            :input-value="active"
            icon
            @click="toggle"
          >
            <v-icon>{{plus.Icon}}</v-icon>
          </v-btn>
        </v-item>
      </v-item-group>
      <v-btn
        text
        @click="next"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
      </v-card-text>
    </v-window-item>    
    <v-window-item :value="4">
      <v-card-text v-if="NewSuiteTemplate.PrimaryScope.SelectedPlus && NewSuiteTemplate.PrimaryScope.SelectedPlus.Option && NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.MultiplePluginsAllowed">
        Choose from below: 
        <v-list-item v-for="(opt,opti) in NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.PluginOptions" :key="opt.itemObjKey">
                     <v-tooltip  
                    top max-width="300"
                    >
                    <template v-slot:activator="{ on, attrs }">
                        <v-list-item-content v-bind="attrs"
                        v-on="on">
                        <v-list-item-title>
                          <v-icon left>
                            {{opt.Icon}}
                          </v-icon>
                          {{opt.Name}}
                        </v-list-item-title>
                        </v-list-item-content>
                         <v-list-item-action><v-switch @change="ToggleUpdatingPluginList()"
                         dense v-model="NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.PluginOptions[opti].Active"
       
            
                    ></v-switch>
                        </v-list-item-action>
                    </template>
                    <span v-html="opt.Description"> 
                    </span>
                </v-tooltip>
          </v-list-item>
      </v-card-text>
      <v-card-text v-if="NewSuiteTemplate.PrimaryScope.SelectedPlus && NewSuiteTemplate.PrimaryScope.SelectedPlus.Option && !NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.MultiplePluginsAllowed">
      </v-card-text>
    </v-window-item>
    <v-window-item :value="5">
      <!-- 4 (essentially "per PLUS" being 4.1 - Multiple Selection options (e.g. Team App "Sales Management" And/or "Filing App") and
    4.2 (OR perhaps "5") being "configuration" of "PLUS Selection" (e/g/ sure "Sales Management", manage Goals, yes or no, manage activities yes or no, etc) -->
        <v-card-text>    
        <v-switch @change="CheckInviteData()" v-model="NewSuiteTemplate.SkipClientInfo" label="Skip Client for Now"/>
      
      <UserLookup :UsersLookupArray="OwnershipUsersArray"  :ModelProp="'Client'" :RecordObj="NewSuiteTemplate" @UpdateUserLookupProp="UpdateUserLookupProp"
      :FieldLabel="'Client'" :Rules="[$store.state.formrules.required]" v-if="!NewSuiteTemplate.ClientisNonMember && !NewSuiteTemplate.SkipClientInfo"
      />
        </v-card-text>
    </v-window-item>
    <v-window-item :value="6">
      <!--  5. Account Information -->
      <v-card-text>  
         <FieldValueEditerComponent style="padding: 0px;" v-for="field in AccountFields" :key="field.itemObjKey" @UpdateEditableField="UpdateEditableField"
                 :Record="NewAccount" :field="field" @onPhotoFileselected="onPhotoFileselected"
                    :SystemEntities="SystemEntities" :PrimaryFieldName="'Invoice_Number'" :RelatedObj="{}" :AppisDarkMode="AppisDarkMode" @onPhotoUpload="onPhotoUpload"
                    @UploadFileSelect="UploadFileSelect"
                        />
                         <v-card flat tile class="transparent">
                  <PhysicalAddressFieldCard
                   :col="{FieldObj: Invoicing_Address}"  
                  :Record="NewAccount" :CanEdit="true" @SaveRecord="SaveRecord" 
                  :System="System" :SystemEntities="SystemEntities" 
                  />
                         </v-card>
      </v-card-text>
    </v-window-item>
    <v-window-item :value="7">
      <!--  6. Process New Site
    TRICKY bit, apart from plugin selection and relationship to PLUS selection...
     do we show marketplace tempalts or NOT? or do we rather show them on "each selection"? -->
      <v-card-text>  
        
      </v-card-text>
    </v-window-item>
    <v-window-item :value="7">
                   <v-card-text>    
                   <v-switch @change="CheckInviteData()" v-model="NewSuiteTemplate.SkipClientInfo" label="Skip Client for Now"/>
                 
                  <UserLookup :UsersLookupArray="OwnershipUsersArray"  :ModelProp="'Client'" :RecordObj="NewSuiteTemplate" @UpdateUserLookupProp="UpdateUserLookupProp"
                  :FieldLabel="'Client'" :Rules="[$store.state.formrules.required]" v-if="!NewSuiteTemplate.ClientisNonMember && !NewSuiteTemplate.SkipClientInfo"
                  />
                   </v-card-text>
                </v-window-item>
    </v-window>     
      <v-card-actions v-if="NewSuiteStep !== 3">
                <v-btn
                  :disabled="NewSuiteStep === 1"
                  text
                  @click="RegressNSWindow()"
                >
                  Back
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  :disabled="NSStepNextDisabled"
                  color="primary"
                  depressed
                  @click="ProgressNSWindow()"
                >
                  Next
                </v-btn>
                 <v-btn v-if="NSSaveEnabled" dark outlined color="green" @click="SaveSuiteTemplate()">
                    Save
                </v-btn>
              </v-card-actions>
        </v-card>
    </v-dialog>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import FieldValueEditerComponent from '@/components/Database/Fields/Input/FieldValueEditerComponent';
import PhysicalAddressFieldCard from '@/components/Database/Fields/Hybrid/FieldCards/PhysicalAddressFieldCard'
import ContentEditableField from '@/components/Database/Fields/Input/ContentEditableField'	
import LandingPageSingle from '@/components/WebPages/LandingPageSingle';	
import UserLookup from '@/components/General/UserLookup'
import SignupPackages from '@/components/WebPages/RenderComponents/SignupPackages'
export default {
    props: ['System','SystemEntities','Directories','SuiteTemplateDialog','InitialSuiteTemplate'],
    components: {FieldValueEditerComponent,PhysicalAddressFieldCard,
    ContentEditableField,LandingPageSingle,UserLookup,SignupPackages},
    data() {
        return {
          scopeoptstep: 0,
          scopestep: 0,
          NewSuiteStep: 1,
            SiteOrgType: '',
            SiteOrgTypeField: {
              id: 'Site_Owner_Type',Name: 'Site_Owner_Type', DisplayName: 'Site Owner Type',
              Type: 'Radio Group',SingleSelect: true, Options: [{ID: 1000001,Name: 'Content Creator'}
              ,{ID: 1000002,Name: 'Sideline Business'},{ID: 1000003,Name: 'Sole Proprietorship'},
              {ID: 1000004,Name: 'Startup'},{ID: 1000005,Name: 'Small Business'},
              {ID: 1000006,Name: 'SME'},{ID: 1000007,Name: 'Corporate Enterprise'},{ID: 1000008,Name: 'Professional Association'},
              {ID: 1000009,Name: 'NPO'}]
            },
            SitePurpose: '',
            ModulesCheck: [
                {id: 'Website_Builder',Name: 'Website',Icon: 'mdi-search-web',Active: false},
                {id: 'Social_Network_Builder',Name: 'Social Network',Icon: 'mdi-home-group',Active: false},
                {id: 'Database_Builder',Name: 'Database Apps',Icon: 'mdi-database',Active: false},
                {id: 'Library_Builder',Name: 'Documentation Builder',Icon: 'mdi-book-education',Active: false}
            ],
            ScopeOptions: [
              // okay long story short - should anythign with "PluginOptios" not perhaps be considered a top level entry?
              //it would make life easier
              //okay my notes before I dispatch, example hereof whre makes sense "Companny Communication"
              //if not under "team portal" it now opens up "Clients y/N" and thus no need to have it under two places
              //Team Social Collab (instead of Team Collab) yeah sure same story
              //but now "Client Portal" uhm, for "service management" makes more sense than just saying "service management" but...?
              {ID: 1000001,Icon: 'mdi-account-supervisor-outline', Name: 'Team App',
              Description: `<p>Looking to make your office work digital?</p>`,
              Pluginid: 'Database_Builder',Options: [
                {ID: 1000006,Icon: 'mdi-chart-line', Name: 'Sales Management',
              Description: `<p>Client Database and Opportunity Pipeline Manager?</p>`,
              IncludedPlugins: ['Standard_Data_Table','Standard_Data_Table','Standard_Data_Table']},//IncludedPlugins challenge. 3 custom entities accounts, contacts and then "WIP table" which is either calendar or std table so?
                {ID: 1000007,Icon: 'mdi-warehouse', Name: 'Warehouse Management',
              Description: `<p>Lightweight Consumable Store Management (e/.g. Trucks managing Tires etc)?</p>`,
              IncludedPlugins: ['Warehouse_&_Products','Warehouse_Inbound','Warehouse_Outbound']},//is it inb/outb always included?
                {ID: 1000008,Icon: 'mdi-file-cabinet', Name: 'Filing App',
              Description: `<p>Want a table of Records, to which you can append photos or documents?</p>`,
              IncludedPlugins: ['Standard_Data_Table']},
                {ID: 1000009,Icon: 'mdi-list-status', Name: 'WIP Management',
              Description: `<p>Want to manage projects, or task progress?</p>`,
              IncludedPlugins: ['Standard_Data_Table']}//custom custom custom but is that not inherited from table type plug right?
              ]},
              {ID: 1000002,Icon: 'mdi-bulletin-board',Name: 'Team Portal',
              Description: `<p>Centralizing Internal Communication with your very own Organisation Portal?</p>`,
              Pluginid: 'Social_Network_Builder',Options: [
                {ID: 1000010,Icon: 'mdi-newspaper', Name: 'Organisation Communication',MultiplePluginsAllowed: true,
                PluginOptions: ['Site_Articles','Site_Blogs','Campaign_Builder','Digital_NoticeBoard']},//articles, blogs, campaignbuilder, noticeboard
                {ID: 1000011,Icon: 'mdi-account-group', Name: 'Collaboration',MultiplePluginsAllowed: true,
                PluginOptions: ['Site_Events','Site_Forums','Site_Groups','Site_Meetups','Site_Polls']},//events, forums, groups, meetups, polls
                {ID: 1000012,Icon: 'mdi-monitor-star', Name: 'Personals',
              Description: `<p>Want users to list personals, and advertise unwanted, or wanted goods?</p>`,
              IncludedPlugins: ['Classifieds']},//Classifieds * this one means this only while others you choose from
              ]},
              {ID: 1000003,Icon: 'mdi-account-heart-outline',Name: 'Client Portal',
              Description: `<p>Want a client portal, whereby clients can interact with your Organisation?</p>`,
              Pluginid: 'Social_Network_Builder',Options: [
                {ID: 1000013,Icon: 'mdi-storefront', Name: 'Online Store',
              Description: `<p>Want an Online Store to sell goods online?</p>`,
              IncludedPlugins: ['Online_Store']},
                {ID: 1000014,Icon: 'mdi-calendar-check', Name: 'Online Bookings',
              Description: `<p>Want to publish calendar events, and allow user booking (e.g. a Gym App, or a Training Schedule App)?</p>`,
              IncludedPlugins: ['Booking_Schedule']},
                {ID: 1000015,Icon: 'mdi-room-service', Name: 'Service Management',MultiplePluginsAllowed: true,
                PluginOptions: ['Booking_Schedule','Standard_Data_Table','Calendar_Jobs']},
                // {ID: 1000008, Name: 'Filing App'},
                // {ID: 1000009, Name: 'WIP Management'}
                // really we need to actually also offer "Promotions" (campbuilder), Merch Store (another online store), LMS (when figured),
                //and then other teamp portal stuff but skip bs for now
              ]},
              // {ID: 1000004,Icon: 'mdi-newspaper-variant-multiple-outline',Name: 'Content Portal',
              // Description: `<p>Want a content portal, articles, blogs or other?</p>`,
              // Pluginid: 'Social_Network_Builder',Options: [
              //   {ID: 1000016,Icon: 'mdi-email-newsletter', Name: 'Articles',IncludedPlugins: ['Site_Articles']},
              //   {ID: 1000017,Icon: 'mdi-typewriter', Name: 'Blogs',IncludedPlugins: ['Site_Blogs']},
              //   {ID: 1000018,Icon: 'mdi-forum', Name: 'Forums',IncludedPlugins: ['Site_Forums']},
              //   //let's hit the rest later
              // ]},
              // {ID: 1000004,Icon: 'mdi-library-shelves',Name: 'Libraries',
              // Description: `Looking for a centralized library to host organizational Knowledge Base?`,
              // Pluginid: 'Library_Builder',Options: [
              //   // {ID: 1000006, Name: 'Sales Management'},
              //   // {ID: 1000007, Name: 'Warehouse Management'},
              //   // {ID: 1000008, Name: 'Filing App'},
              //   // {ID: 1000009, Name: 'WIP Management'}
              // ]},//really Libraries is "TBD"
            ],
            DefaultSelectedTemplates: {
              Website_Builder: '',
              Social_Network_Builder: '',
              Database_Builder: '',
              Documentation_Builder: '',
            },
            SelectedTemplates: {
              Website_Builder: '',
              Social_Network_Builder: '',
              Database_Builder: '',
              Documentation_Builder: '',
            },
            NewSuiteTemplateid: '',
            NewSuiteTemplateCreateDoc: '',
            NewSuiteTemplates: [],
            NewAccount: {},
            NewSuiteTemplate: {
              PluginDataBase: {}
            },
            DefaultNewSuiteTemplate: {
              PluginDataBase: {}
            },
            ViewingTemplate: false,
            NewTempPrimCat: '',
            NewTempSecCat: '',
            PrimaryCategories: [
                {ID:1000001,Name: 'Site'},
                {ID:1000002,Name: 'Page'}
            ],
            SecondaryCategories: [
                {ID: 1000001, Name: 'Website'},
                {ID: 1000002, Name: 'Social Network'},
                {ID: 1000003, Name: 'Group Site'},
                {ID: 1000004, Name: 'Featured Member'}
            ],            
            UpdatingPluginList: false,
            Invoicing_Address: {Name: 'Invoicing_Address',FieldBreakdown: [],DisplayName: 'Invoicing Address',CommonFieldType: 'Physical Address',Type: 'Common Field'},
        }
    },	
    computed:{
      ShowSiteCost(){
        return this.NewSuiteStep === 3 || this.NewSuiteStep === 4 || this.NewSuiteStep === 5
      },
      ComputedScopeOptions(){
        return this.ScopeOptions.map(plus => {
          let plusobj = Object.assign({},plus)
          plusobj.Price = 0
          if(plusobj.Pluginid){
            let plug = this.RAPlugins.find(obj => obj.id === plusobj.Pluginid)
            plusobj.PluginObject = {
              id: plug.id,
              Name: plug.Name,
              Price: plug.Price,
              Description: plug.Description,
              Level: plug.Level,
              Module_Category: plug.Module_Category,
              Type: plug.Type
              }
            plusobj.Price = plug.Price
          }
          plusobj.DisplayName = plus.Name
          plusobj.Name = plusobj.Name.split(' ').join('_')
          plusobj.Options = plusobj.Options.map(opt => {
            let optobj = Object.assign({},opt)
            optobj.ParentName = plusobj.Name
            optobj.DisplayName = optobj.Name
            optobj.Name = optobj.Name.split(' ').join('_')
            if(optobj.IncludedPlugins){
              optobj.IncludedPlugins = optobj.IncludedPlugins.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug)
                return {
                  id: plugobj.id,
                  Name: plugobj.Name,
                  Icon: plugobj.Icon,
                  Price: plugobj.Price,
                  Description: plugobj.Description,
                  Level: plugobj.Level,
                  Module_Category: plugobj.Module_Category,
                  Type: plugobj.Type
                  }
              })
              optobj.Package = {
                Name: optobj.DisplayName,
                ParentName: optobj.ParentName,
                // SubText: plusobj.DisplayName,
                  Color: {hexa: '#9c27b0 '},
                Description: optobj.Description,
                Price: plusobj.Price+optobj.IncludedPlugins.map(plug => {
                  return plug.Price
                }).reduce((a, b) => a + b, 0),
                // KeyPoints: optobj.IncludedPlugins.map(plug => {
                //   return {
                //     title: plug.Name,
                //     icon: plug.Icon,
                //     Active: true
                //   }
                // })
              }
            }
            if(optobj.PluginOptions){
              optobj.PluginOptions = optobj.PluginOptions.map(plug => {
                let plugobj = this.RAPlugins.find(obj => obj.id === plug)
                let retobj = {
                  id: plugobj.id,
                  Icon: plugobj.Icon,
                  Name: plugobj.Name,
                  Price: plugobj.Price,
                  Description: plugobj.Description,
                  Level: plugobj.Level,
                  Module_Category: plugobj.Module_Category,
                  Type: plugobj.Type,
                  }
                  if(typeof retobj.Active  === 'undefined'){
                    retobj.Active = false
                  }
                  return retobj
              })
              optobj.Package = {
                Name: optobj.DisplayName,
                ParentName: optobj.ParentName,
                // SubText: plusobj.DisplayName,
                Description: `<p>Options for this package includes:</p>`,
                Color: {hexa: '#9c27b0 '},
                Price: plusobj.Price+optobj.PluginOptions.map(plug => {
                  return plug.Price
                }).reduce((a, b) => a + b, 0),
                KeyPoints: optobj.PluginOptions.map(plug => {
                  return {
                    title: plug.Name,
                    icon: plug.Icon,
                    Active: true
                  }
                })
              }
              if(optobj.MultiplePluginsAllowed){
                optobj.Package.PriceFootnote = 'Price indicative of all below selected'
              }
              else {
                optobj.Package.PriceFootnote = 'Price dependant on plugins selected'
              }
            }
            //console.log('optobj.Package',optobj.Package)
            return optobj
          })
          return plusobj
        })
      },
      SitesDirectory(){
          return this.Directories.find(obj => obj.id === 'Systems')
      },
      SitesCategoryField(){
          return this.SitesDirectory && this.SitesDirectory.ListPrimaryField ? 
          this.SitesDirectory.ListPrimaryField : ''
      },
      AccountFields(){
         return [{Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},this.SitesCategoryField]
        //return [{Name: 'Company_Name',DisplayName: 'Company Name',Type: 'Single Line Text'},{...this.Invoicing_Address}]
      },
      WebsiteOptionLabel(){
        return this.NewSuiteTemplate.SiteApplication === 'Site/Listing Only' || 
        this.NewSuiteTemplate.SiteApplication === 'Site/Listing PLUS' ? 'Custom Domain (POA - Office Intervention)' : 'Website'
      },
      NSSaveEnabled(){
        return this.NewSuiteStep === 5 && !this.WebDeveloper || this.NewSuiteStep === 6 && this.WebDeveloper
      },
      WebDeveloper(){
        return this.userLoggedIn && this.userLoggedIn.IsWebDeveloper || this.userIsAdmin
      },
      NSStepNextDisabled(){
        return this.NSSaveEnabled
        // || this.NewSuiteStep === 3 && !this.NewSuiteTemplate.Description && !this.NewSuiteTemplate.Name
        //|| this.NewSuiteStep === 1 && !this.ModulesCheck.find(obj => this.SelectedTemplates[obj.id])
        //we now allow without templates, purchase templates afterwards|| this.NewSuiteStep === 2 && !this.ModulesCheck.find(obj => this.SelectedTemplates[obj.id])
      },
      NewSuitePlusTotal(){
        return this.UpdatingPluginList || !this.SuiteTemplateDialog ? 0 : this.ComputedScopeOptions.filter(plus => {
          //console.log('this.NewSuiteTemplate.PLUSOptions',this.NewSuiteTemplate.PLUSOptions,plus.Name)
            return this.NewSuiteTemplate.PrimaryScope.SelectedPlus && this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Name === plus.Name
            }).map(plus => {
              //console.log('plus',plus,this.NewSuiteTemplate.PLUSOptions)
            let Price = plus.Price
            let optionsprice = plus.Options.filter(opt => {
              return this.NewSuiteTemplate.PrimaryScope.SelectedPlus && this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option &&
              this.NewSuiteTemplate.PrimaryScope.SelectedPlus && this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.Name === opt.Name
            })
            .map(opt => {
              let OptPrice = 0
              if(opt.IncludedPlugins){
                OptPrice = opt.IncludedPlugins.filter(plug => {
                  return true //purely always true it's default included right?
                  //this.NewSuiteTemplate.PLUSOptions[opt.Name]
                }).map(plug => {
                  //console.log('plug',plug)
                  return plug.Price
                }).reduce((a, b) => a + b, 0)
              }
              if(opt.PluginOptions){
                OptPrice = opt.PluginOptions.filter(plug => {
                  return this.NewSuiteTemplate.PrimaryScope.SelectedPlus && this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option && 
                  this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.PluginOptions && 
                  this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.PluginOptions.find(obj => obj.id === plug.id) && 
                  this.NewSuiteTemplate.PrimaryScope.SelectedPlus.Option.PluginOptions.find(obj => obj.id === plug.id).Active
                  //but - filter out those that are NOT multiple charge in case they feature multiple palces. you get?
                  //like site artiles for team portal, but also site articles for website. and like - oh yeah no double charge on that
                })
                .map(plug => {
                  //console.log('plug',plug)
                  return plug.Price
                }).reduce((a, b) => a + b, 0)
              }
              return OptPrice
            }).reduce((a, b) => a + b, 0)
              //console.log(plus,'Price',Price,'optionsprice',optionsprice)
            return Price+optionsprice
          }).reduce((a, b) => a + b, 0)
      },
      SuitePlusTotal(){
        return this.UpdatingPluginList || !this.SuiteTemplateDialog ? 0 : this.ComputedScopeOptions.filter(plus => {
          //console.log('this.NewSuiteTemplate.PLUSOptions',this.NewSuiteTemplate.PLUSOptions,plus.Name)
            return this.NewSuiteTemplate.PLUSOptions && this.NewSuiteTemplate.PLUSOptions[plus.Name]
          }).map(plus => {
            //console.log('plus',plus,this.NewSuiteTemplate.PLUSOptions)
          let Price = plus.Price
          let optionsprice = plus.Options.map(opt => {
            let OptPrice = 0
            if(opt.IncludedPlugins){
              OptPrice = opt.IncludedPlugins.filter(plug => {
                return this.NewSuiteTemplate.PLUSOptions[opt.Name]
              }).map(plug => {
                //console.log('plug',plug)
                return plug.Price
              }).reduce((a, b) => a + b, 0)
            }
            if(opt.PluginOptions){
              OptPrice = opt.PluginOptions.filter(plug => {
                return this.NewSuiteTemplate.PluginDataBase[plug.id] && this.NewSuiteTemplate.PluginDataBase[plug.id].Active
                //but - filter out those that are NOT multiple charge in case they feature multiple palces. you get?
                //like site artiles for team portal, but also site articles for website. and like - oh yeah no double charge on that
              })
              .map(plug => {
                return plug.Price
              }).reduce((a, b) => a + b, 0)
            }
            return OptPrice
          }).reduce((a, b) => a + b, 0)
          return Price+optionsprice
        }).reduce((a, b) => a + b, 0)
        //okay technically. the price to a listing needs to be added. And the price to a website, and/or ustom domain
        //remember that domain cost depends on the category. Or do we pass R100 for all? either way that's the final logic.
        //Well just about.. remember now, actually two invoices, two payments or, 1 payment that is a mixed type. If we purchased plugins any other day, after installing
        //we would get plugin subscription. NOW we talking about site directory listing alongside a freaking list of plugins. 
        //I am not too sure any of this is allowed through our payment dialog
        //on top of that I don't believe the payment dialog is relevant at all well - because this is a "quote" is it not?
      },
        NewSuiteTotal(){
          //the issue is, we need to calculate multiple instances, so IncludedPlugins basically
         return this.UpdatingPluginList || !this.SuiteTemplateDialog ? 0 : this.RAPlugins.filter(plug => {
                return this.NewSuiteTemplate.PluginDataBase[plug.id].Active && plug.Module_Category && plug.Module_Category.Name === 'Module'
                //yes, although we know modules are now priced at R0 - so this mean nothing. So now
                //but there are "general plugins" and basically these are plugins not even ofered here, although...how do we separate unless we start actually linking to cats? 
                //meaning the group cat field we wanted to add?

            }).map(plug => {
                return plug.Price
            }).reduce((a, b) => a + b, 0)
      },
      RAPlugins(){
          return this.$store.state.RAPlugins
        },
        EntitiesArray(){
        return this.SystemEntities
      },
      DefaultSystemEntities(){
        return this.EntitiesArray
        .filter(ent => {
          //Entities on RA db has boolean "Default_System_Entity" and this maarks the RA store Template as a default install entity 
          return this.$store.state.DefaultEntities.find(obj => obj.DisplayName.split(' ').join('_') == ent.id)
        })
        .map(ent => {
          let stateent = this.$store.state.DefaultEntities.find(obj => obj.DisplayName === ent.DisplayName)
          let entobj = JSON.parse(JSON.stringify(ent))
          if(stateent && stateent.Table_Icon){
            entobj.Table_Icon = stateent.Table_Icon
          }
          if(stateent && stateent.Security === 'Custom Roles'){
            entobj.CustomCreateRoles = []
            entobj.CustomDeleteRoles = []
            entobj.CustomListRoles = []
            entobj.CustomGetRoles = []
            entobj.CustomUpdateRoles = []
          }
          else {
            this.$store.state.BURoles.map(role => {
              entobj[role.Name] = []
            })
          }
          return entobj
        })
      },
        prevSiteTemplateChildIndex () {
        this.SiteTemplateChildIndex = this.SiteTemplateChildIndex - 1 < 0
          ? this.ComputedTemplateChildren.length - 1
          : this.SiteTemplateChildIndex - 1
      },
        currentNSDescription(){
          if(this.NewSuiteStep === 3){
            return this.ComputedScopeOptions[this.scopestep].Description
          }
          else{
           switch (this.NewSuiteStep) {
              case 1: return `Let us configure the Type of Organisaiton that would own this Site.`
              case 2: return `Let us configure the Scope for your Site. This can be changed later after creating your Site.`
              case 3: return `Let's capture the Site information`
              case 4: return `Make your selection from the package options`
              case 5: return `Building for a Client? Select your Client from the existing Members, or invite them!`
              case 6: return `We need the Bililng info for the Account that would be provided for the Site.`
              default: return 'New Site'
            } 
          }
        
      },
      currentNSTitle(){
        switch (this.NewSuiteStep) {
          case 1: return 'Organisation Type'
          case 2: return 'Scope'
          case 3: return 'Site'
          case 4: return 'Package Selection'
          case 5: return 'Client Info'
          case 6: return 'Billing Info'
          default: return 'New Site'
          // case 1: return 'Basic'
          // case 2: return 'Modules and Plugins'
          // case 3: return 'Client Info'
          // default: return 'New Suite'
        }
      },
        UsersArray(){
        return this.$store.state.Users
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {
        SocialItemInteractMethod(v){   
        let acceptedmethods = ['ActivateSuiteTemplateDialog']   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      // this.PrepareAddressField('Company_Address')
      // this.PrepareAddressField('Billing_Address')
      this.PrepareAddressField('Invoicing_Address')
      this.NewSuiteTemplate = Object.assign({},this.InitialSuiteTemplate)
      this.NewSuiteTemplate.PrimaryScope = {
        Website: true,
        Listing: true
      }
    },
    methods:{
      SelectSiteScopeOption(pack){
        //console.log(pack)        
        let plus = this.ComputedScopeOptions.find(obj => obj.Name === pack.ParentName)
        let option = plus.Options.find(obj => obj.DisplayName === pack.Name)
        // console.log(option)
        // console.log(plus)
        // this.CheckScopeOptionSelection(plus,option)
        this.NewSuiteTemplate.PrimaryScope.SelectedPlus = {...plus,Option: {...option}}
        if(option.PluginOptions){
        this.NewSuiteStep = 4
        }
        else{
          this.NewSuiteStep = 5
        }
        this.ToggleUpdatingPluginList()
        console.log(this.NewSuiteTemplate)
      },  
      UpdateKeyPointSelection(pack,adv){
        console.log(pack,adv)
      },
      next () {
        this.scopestep = this.scopestep + 1 === this.ComputedScopeOptions.length
          ? 0
          : this.scopestep + 1
      },
      prev () {
        this.scopestep = this.scopestep - 1 < 0
          ? this.length - 1
          : this.scopestep - 1
      },

      CheckScopeOption(plus){
        //UpdatingPluginList
        this.NewSuiteTemplate.PluginDataBase[plus.Pluginid].Active = this.NewSuiteTemplate.PLUSOptions[plus.Name]
        this.ToggleUpdatingPluginList()
        //little too direct but for now let's pass it
      },
      CheckScopeOptionSelection(plus,option){
        //console.log(plus,option,this.NewSuiteTemplate)
        if(!this.NewSuiteTemplate.PLUSOptions[plus.Name] && this.NewSuiteTemplate.PLUSOptions[option.Name]){
          this.NewSuiteTemplate.PLUSOptions[plus.Name] = true
          //this.NewSuiteTemplate.PLUSOptions[option.Name]
          
        }
        if(option.IncludedPlugins){
          option.IncludedPlugins.map(plug => {
            this.NewSuiteTemplate.PluginDataBase[plug.id].Active = this.NewSuiteTemplate.PLUSOptions[option.Name]
          })
        }
        this.ToggleUpdatingPluginList()
      },
      CheckScopeOptionSelectionOptions(plus,option,opt){
        //console.log(plus,opt,this.NewSuiteTemplate)
        if(option.PluginOptions){
          option.PluginOptions.map(plug => {
            if(this.NewSuiteTemplate.PLUSOptions[plug.id]){
            this.NewSuiteTemplate.PluginDataBase[plug.id].Active = true
            this.NewSuiteTemplate.PluginDataBase[plus.Name] = true
            this.NewSuiteTemplate.PLUSOptions[option.Name] = true
            this.CheckScopeOptionSelection(plus,option)
            }
            else{
              this.NewSuiteTemplate.PluginDataBase[plug.id].Active = false
            }
          })
          this.ToggleUpdatingPluginList()
        }
      },
      PrepareAddressField(fieldname){
        let field = this[fieldname]
        field.FieldBreakdown = []
        let StreetNumber = {Name: field.Name+'_Street_Number',DisplayName: 'Street Number', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: '1'}
        let StreetName = {Name: field.Name+'_Street_Name',DisplayName: 'Street Name', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: '', SampleText: 'Long Street'}
        let Suburb = {Name: field.Name+'_Suburb',DisplayName: 'Suburb', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'SmallVille'}
        let City = {Name: field.Name+'_City',DisplayName: 'City', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'Big Town'}
        let PostalCode = {Name: field.Name+'_Postal_Code',DisplayName: 'Postal Code', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, SampleText: '1777'}
        let StateProvince = {Name: field.Name+'_State_Province',DisplayName: 'State Province', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'FarmState'}
        let CountryRegion = {Name: field.Name+'_Country_Region',DisplayName: 'Country Region', Primary: '', Secondary: '', Type: 'Child Common Field', IsSearch: true, IsFilter: true, SampleText: 'DisneyLand'}
        field.FieldBreakdown = []
        field.FieldBreakdown.push(StreetNumber)
        field.FieldBreakdown.push(StreetName)
        field.FieldBreakdown.push(Suburb)
        field.FieldBreakdown.push(City)
        field.FieldBreakdown.push(PostalCode)
        field.FieldBreakdown.push(StateProvince)
        field.FieldBreakdown.push(CountryRegion)
      },
      SaveRecord(payload){
        //console.log(payload,this.NewAccount)
      },
      ConfigureApplicationOption(option){
        if(option === 'Skip Listing'){
          this.NewSuiteTemplate.PrimaryScope.Listing = false
          this.NewSuiteTemplate.PrimaryScope.Website = false
        }
        else{
           this.NewSuiteTemplate.PrimaryScope.Listing = true
           this.NewSuiteTemplate.PrimaryScope.Website = true
        }
      },
      toggleMultipleRadio(Record,field,_id) {
            //console.log(_id)
          if(!field.SingleSelect){
            if(!Record[field.id]){
                  Record[field.id] = []
                }
                if (Record[field.id].includes(_id)) {
                Record[field.id] = Record[field.id].filter((i) => {
                return i !== _id;
                });
            } else {
                Record[field.id] = Record[field.id].concat(_id);
            }
          }
          console.log(Record)            
        },
        CurrencyFormatter(value,currency){
        const currencyformatter = this.PrepareCurrency(currency)
        let formalformat = currencyformatter.format(value)
        return formalformat
        },
        PrepareCurrency(currency){			
        let currobj = this.$store.state.Currencies.find(obj => obj.Currency === currency)
        let lang = this.$store.state.DefaultCurrency.LangForm
        if(currobj){
            lang = currobj.LangForm
        }
        return new Intl.NumberFormat(lang, {			
                style: 'currency',			
                currency: currency,			
                minimumFractionDigits: 2			
            })
        
        },
        MyMarketplaceTemplatesFiltered(modid){
        let secondcat = modid === 'Website_Builder' ? 'Website' : modid === 'Social_Network_Builder' ? 'Social Network' : 'TBD'
        // let allowedtemplates = this.MyMarketplaceTemplates.concat(this.OtherMarketplaceTemplates.filter(item => {
        //   return this.userLoggedIn.ContentPurchases.find(obj => obj.Contentid === item.id)
        // }))
        let allowedtemplates = this.MyMarketplaceTemplates
        return allowedtemplates.filter(item => {
          return item.Category.Name === secondcat && !item.Parentid
        })
      },
      ToggleUpdatingPluginList(){
            this.UpdatingPluginList = true
            
            setTimeout(() => {
                this.UpdatingPluginList = false
            }, 20);
            //OKay this we could extend and should extend. Run through entire selected list. Essentially, 
            //1. If the parent module not activated then activate it but also
            //2. If a "shared" thing exist, like SNB and WB...then activate BOTH shared modules. you get?
            //3. finally also if I had a templte value in SelectedTemplates for mod.id I probably need to remove it if "deactivated"
        },
      PluginList(item){
        let DataSingleBuilder = item.id === 'Database_Builder'
        let categorymatches = item.id === 'Database_Builder' ? ['Database Builder','DBB'] : item.id === 'Database_Builder' ? ['Social Network Builder','SNB'] : 
        item.id === 'Documentation_Builder' ? ['Documentation Library'] : ['Website Builder','WB']
        //sconsole.log('item',item,'categorymatches',categorymatches)
        //I decided to drop the ,'WB' to prevent Social Mod plugins from being activated through website mod...right?
        return this.RAPluginsMatched(categorymatches,DataSingleBuilder)
        //this.DataSingleBuilder ? this.AppPluginsMatched(['Database Builder','DBB']) : this.RAPluginsMatched(['Website Builder','WB'])

      },
      AppPluginsMatched(categorymatches){
            let finalcategories = categorymatches.map(cat => {
          return cat.split(' & ')
        }).flat()
        return this.RAPlugins.filter(plug => {
          let plugcat = plug.Module_Category.Name.split(' & ').flat()
          return finalcategories.some(r=> plugcat.includes(r))
          //|| plug.Module_Category.Name === 'General'
        }).filter(item => {
           return item.Exclusively_as_Extension
        }).filter(plug => {
          let newappplugins = this.ActiveSuiteApp.Plugins.map(plug => {
            return plug.id
          })
          //console.log(newappplugins)
          return !newappplugins.includes(plug.id)
        })
        },
      ProgressNSWindow(){
        console.log(this.NewSuiteTemplate,this.NewAccount)
        if(this.NewSuiteStep === 2 && this.NewSuiteTemplate.SiteApplication === 'Site/Listing Only'){
          this.NewSuiteStep = this.WebDeveloper ? 4 : 5
        }
        else if(this.NewSuiteStep === 4 && !this.WebDeveloper){
          this.NewSuiteStep = 6
        }
        else{
          this.NewSuiteStep++
        }        
      },
      RegressNSWindow(){
        if(this.NewSuiteStep === 4 && this.NewSuiteTemplate.SiteApplication === 'Site/Listing Only' || 
        this.NewSuiteStep === 5 && this.NewSuiteTemplate.SiteApplication === 'Site/Listing Only'){
          this.NewSuiteStep = 2
        }
        else if(this.NewSuiteStep === 6 && !this.WebDeveloper){
          //tricky depending selected plus but yeah
          this.NewSuiteStep = 3
        }
        else{
          this.NewSuiteStep--
        }        
      },
      ActivateSuiteTemplateDialog(){
            //TEchnically we also need a "push to Suite"            
            if(this.ActiveMarketTemplate){
              if(this.ActiveMarketTemplate.Category.Name === 'Website'){
                //nt when "web page" right?
                if(!this.NewSuiteTemplate.PluginDataBase.Website_Builder){
                  this.NewSuiteTemplate.PluginDataBase.Website_Builder = this.ModulesCheck.find(obj => obj.id === 'Website_Builder')
                }
               this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active = true 
              }
              if(this.ActiveMarketTemplate){
                //WRONG...because you may have add after the fact, this must be done before dial opens
                this.NewSuiteTemplate.PluginDataBase = this.ActiveMarketTemplate.PluginDataBase
              }
            }
            this.RAPlugins.map(mod => {
              if(typeof this.NewSuiteTemplate.PluginDataBase[mod.id] === 'undefined'){
               this.NewSuiteTemplate.PluginDataBase[mod.id] = {
                id: mod.id,
                Name: mod.Name,
                Active: false,
                Module_Category: mod.Module_Category
              } 
              }              
              //ALSO CHECK IF FROM TEMPLATE, IF SO, ASSIGN IT'S VALUE
              
            })
            this.NewSuiteTemplate.Business_Unit = {id: 'Head_Office',Name: 'Head Office'}
            this.NewSuiteTemplate.Business_Unitid = 'Head_Office'
            this.SuiteTemplateDialog = true

        },
        CheckInviteData(){
            if(this.NewSuiteTemplate.ClientisNonMember && !this.NewSuiteTemplate.InviteData){
                this.NewSuiteTemplate.InviteData = {}
            }
            if(this.NewSuiteTemplate.SkipClientInfo || !this.NewSuiteTemplate.ClientisNonMember){
                delete this.NewSuiteTemplate.InviteData
            }
        },
        UpdateUserLookupProp(Prop,Value,RecordObj){
            RecordObj[Prop] = Value
            RecordObj[Prop+'id'] = Value.id
        },
        CancelSaveSuiteTemplate(){
            this.SuiteTemplateDialog = false
            this.NewSuiteTemplate = Object.assign({},this.DefaultNewSuiteTemplate)
            this.NewAccount = {}
            this.SelectedTemplates = Object.assign({},this.DefaultSelectedTemplates)
            this.NewSuiteStep = 1
            this.scopestep = 0
            this.NewSuiteTemplateCreateDoc = ''
            this.NewSuiteTemplateid = ''
            this.NewSuiteTemplates = []
            this.$emit('CancelSaveSuiteTemplate')
        },
        SaveSuiteTemplate(){
          //we need to check this.SelectedTemplates
          //get the website template, get the scoial network template etc...make this shit work bob!
          let templates = this.MyMarketplaceTemplates.filter(temp => {
            return this.SelectedTemplates.Website_Builder === temp.id || this.SelectedTemplates.Social_Network_Builder === temp.id
             || this.SelectedTemplates.Database_Builder === temp.id || this.SelectedTemplates.Documentation_Builder === temp.id
          })
          //we got templates and each has a parent and each parent get added then children....
           	
            let itemdb = this.clientDB ? this.clientDB : db
            let path = itemdb.collection('SystemConfig')
            let ref = path.doc()
            this.NewSuiteTemplateid = ref.id
            let newdocid = this.NewSuiteTemplateid
            let user = {}
            let props = ['id','Name','Surname','Full_Name','Gender','Business_Unit','Business_Unitid','Title','Email','Profile_Photo']
            props.map(prp => {
              if(typeof this.userLoggedIn[prp] !== 'undefined'){
                user[prp] = this.userLoggedIn[prp]
              }
            })
            let date = new Date()
            let systemid = process.env.VUE_APP_RA_SYSTEM_ID
            //let systemurl = this.System.ClientAppURL
            let defobj = {
                ...this.NewSuiteTemplate,
                SelectedTemplates: this.SelectedTemplates,
                Templateid: '',
                Systemid: systemid,
                //ClientAppURL: systemurl,
                PublishStatus: 'Draft',
                Owner: user,          
                Ownerid: user.id,
                RouteName: this.$route.name,
                Created_By: user,          
                Created_Byid: user.id,
                Modified_By: user,          
                Modified_Byid: user.id,
                Created_On: date,
                Modified_On: date,
                HasClientRecord: false,
            } 
            if(this.SuiteInstallerDialog){
              //the logic is, if SuiteInstallerDialog we are transfering to client db, thus ClientappURL matters
              let systemurl = this.System.ClientAppURL
              defobj.ClientAppURL = systemurl
            }
             //defobj.Templateid = this.ActiveMarketTemplate.id MISSING but I don't thinkn it's useful
            if(!defobj.InviteData){ 
            this.$store.commit('setCustomProcessingDialog',true)
            this.CreateSiteAccount(user,defobj,itemdb,newdocid,templates)
            
            }
            else{
                defobj.InviteData.RelatedObj = {
                  id: newdocid,
                  Name: this.NewSuiteTemplate.Name,
                  identifier: 'Name',
                  collection: 'suitetemplates',
                  Link: '/Your-Domain/'+newdocid+'/Main'
                }
                defobj.InviteData.RelatedField = 'Client'
                //only as a note this makes no sense coming from tablemain right? but we must assignrelatedField how do we do that?
                // this.$store.commit('setRelatedRecordObj',RelatedObj)
                // this.$store.commit('SetSocialItemInteractMethodProp',field) 
                // this.$store.commit('SetSocialItemInteractMethod','ActivateAddNewLookupDialog')

                this.NewSuiteTemplateCreateDoc = defobj
                this.NewSuiteTemplates = templates
                this.$emit('ActivateProcessing',true)
                this.SendInvitetoUser(defobj.InviteData)
            }

        },
        
        
          //TOO FREAKING COMPLICATED, NEW SUITE IS NOT PUBLISHED YET DUDE WON'T SEE SO WE CANCELLED THIS ON CREATE LOGIC
        // PrepareInvitetoUser(editedInvite){
        //     this.NewSuiteTemplate.InviteData = editedInvite
        //     console.log('this.NewSuiteTemplate',this.NewSuiteTemplate)
        // },
        // SendInvitetoUser(editedInvite){
        //     //lol, technicall first complete the frigging data
        //     this.$store.commit('SetSocialItemInteractMethodProp',editedInvite) 
        //     this.$store.commit('SetSocialItemInteractMethod','SendInvitetoUser')
        // },
        // CancelInvitingUserEmail(){
        //     //gonna leave it here but blank. nothing went to appnot, why cancell right?
        //     //this.$store.commit('SetSocialItemInteractMethod','CancelInvitingUserEmail') 
        // },
        // AssignInviteid(result){
        //   //console.log('AssignInviteid',this.ItemOperationalDB)
        //   this.NewSuiteTemplateCreateDoc.ClientInviteID = result.InviteID
        //   //OKAY WOAH THIS WON'T WORK WE NEED THE BELOW PASSED PROPS SO
        //   //1. assign these props as globalvariable in this compo then
        //   //2 userinv ,ust be a comp recycle here...
        //   let path = db.collection('SystemConfig')
        //   let newdocpath = path.doc(this.NewSuiteTemplateid)
        //   this.ProcessNewSuiteTemplate(this.NewSuiteTemplateCreateDoc,newdocpath,this.NewSuiteTemplateid,this.NewSuiteTemplates)
        // },
        CreateSiteAccount(user,defobj,itemdb,newdocid,templates){
          //BELOW CREATES A SITE ACCOUNT FOR ON SITE HOSTING,
          //UNSURE IF ActivateSuiteTemplateInstallerDialog CHAIN CALLS THIS METHOD BUT IF SO, GOTTA SHARPEN UP AS THAT WOULD REQUIRE SUITE OWNERSHIP BA ON RA
          let vm = this
          vm.$store.commit('setCustomProcessingDialogText','Creating Account')
          let payinguser = ''
          if(this.NewSuiteTemplate.SkipClientInfo){
            payinguser = this.OwnershipUsersArray.find(obj => obj.id === this.userLoggedIn.id)
          }
          else{
            payinguser = this.NewSuiteTemplate.Client
          }
          if(!payinguser){
            payinguser = user
          }
          let rauser = this.OwnershipUsersArray.find(obj => obj.id === this.userLoggedIn.id)
          if(!rauser){
            rauser = user
          }
          let status = vm.$store.state.DefaultStatusField
          let buobj =  {id: 'Head_Office',Name: 'Head Office'}
          let baseobj = {          
            Business_Unit: buobj,
            Business_Unitid: buobj.id,
            Created_On: rauser.Created_On,
            Owner: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Ownerid: rauser.id,
            Created_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Created_Byid: rauser.id,
            Created_On: new Date(),
            Modified_By: {id: rauser.id, Name: rauser.Name, Surname: rauser.Surname, Full_Name: rauser.Full_Name},
            Modified_Byid: rauser.id,
            Modified_On: new Date(),
            Status: status.DefaultOption,
            Status_Reason: status.DefaultLevel2Option,
            Billing_Type: {ID: 1000001, Name: 'COD'}
          }
          let newbaobj = {
            ...baseobj,
            Client_Reference: this.NewAccount.Company_Name,
            Account_Name: this.NewAccount.Company_Name,
            Account_Type: {ID: 1000007, Name: 'Site Account'},
            Type_Default: false,
            PaymentSchedule: 'Monthly',
            IsFinal: false,
            User: payinguser,
            Userid: payinguser.id,
            Total_Invoiced: 0,
            Total_Paid: 0,
          }
          this.Invoicing_Address.FieldBreakdown.map(brd => {
            if(this.NewAccount[brd.Name]){
                newbaobj[brd.Name] = this.NewAccount[brd.Name]
            }
          })
          //IF WE DO LOOKUPOBJ TO BA FOR NEW SITE IT IS LOOKUP TO "SYSTEMS" ENTRY, WHERE APPS AND PLUGINS RESIDE YES!
          vm.$store.dispatch('CreateSearchTitle',newbaobj.Client_Reference).then(basearchtitle => {
            const functions = firebase.functions();
            const AssignAutoNumber = functions.httpsCallable('AssignAutoNumber');
            console.log('billingaccount',newbaobj)
            let payload = {
              Docid: 'BillingAccounts_Customer_ID',
              Data: newbaobj,
              siteid: process.env.VUE_APP_RA_PROVIDER_ID
            }            
            vm.$store.commit('setCustomProcessingDialogText','Registering Account Number')            
              AssignAutoNumber(payload).then(result => {
                newbaobj.Customer_ID = result.data
                newbaobj.id = newbaobj.Customer_ID
                newbaobj.SearchQuery = basearchtitle    
                defobj.id = newbaobj.Customer_ID        
                //ADDITIONALLY - SHOULD WE ALLOW TEMPALTES FROM MARKET HERE, THE BILLING WOULD APPLY TO THE BA HERE, OR THE MARKET BA DEPENDING - ALTHOUGH REMAINS A ONCE OFF PURCHASE YES
                //REFERRING TO PURCHASING A MARKETPLACE TEMPLATE IN ORDER TO CREATE A SITE, THAT IS
                //would also impact newdocid
                newdocid = newbaobj.id
                
                let masterref = itemdb.collection('SystemConfig').doc(newdocid)
                db.collection('Databases').doc(vm.$store.state.ActiveSuiteid).collection('billingaccounts').doc(newbaobj.id).set(newbaobj).then(newba => {
                  defobj.BillingAccount = {Client_Reference: newbaobj.Client_Reference,Customer_ID: newbaobj.Customer_ID,id: newbaobj.id},
                  defobj.BillingAccountid = newbaobj.id,      
                  //actually the answer lies here
                  //first of all we need to create templates where there aren;t default templates being default modules.
                  vm.ProcessNewSuiteTemplate(baseobj,defobj,itemdb,masterref,newdocid,templates)
                  // let defaulttemplates = [
                  //   {
                  //     Primary_Category: {ID: 1000002,Name: 'Site'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Website'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000002,Name: 'Site'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Social Network'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000003,Name: 'Library'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Photo Library'}
                  //   },
                  //   {
                  //     Primary_Category: {ID: 1000004,Name: 'App'},
                  //     Secondary_Category: {ID: 1000001,Name: 'Website'}
                  //   }
                  // ]
                })    
                
              })
          })
          //Customer_ID id
          
        },
        ProcessNewSuiteTemplate(baseobj,defobj,itemdb,masterref,newdocid,templates) {
          //right now THINK
          //1. New structure, we do NOt add to "suitetemplates" and not subcol "websites", it's main col 'Websites' etc
          masterref.set(defobj).then(newdoc => {
            let webdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Website_Builder.Active : false
            }
            let webprops = ['Company_Name','Site_Category','Site_Class','Site_Type']
            webprops.map(prp => {
              if(typeof this.NewAccount[prp] !== 'undefined'){
                webdoc[prp] = this.NewAccount[prp]
              }
            })
            let socialdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Social_Network_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Social_Network_Builder.Active : false
            }
            let dbdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Documentation_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Documentation_Builder.Active : false
            }
            let libdoc = {
              ...baseobj,
              Admin: baseobj.Owner,
              Adminid: baseobj.Ownerid,
              Active: this.NewSuiteTemplate.PluginDataBase.Database_Builder.Active ? this.NewSuiteTemplate.PluginDataBase.Database_Builder.Active : false
            }
            itemdb.collection('Websites').doc(newdocid).set(webdoc)
            itemdb.collection('SocialNetworks').doc(newdocid).set(socialdoc)
            itemdb.collection('Libraries').doc(newdocid).set(dbdoc)
            itemdb.collection('Databases').doc(newdocid).set(libdoc)
            
          let photolib = Object.assign({},this.SystemPhotoLibraries.find(obj => obj.id === 'System_Assets'))
              photolib.CustomTags = []
              photolib.CustomTagsInteger = 1000000
              let defastlibref = itemdb.collection('Libraries').doc(newdocid).collection('PhotoLibraries').doc(photolib.id)
              defastlibref.set(photolib).then(addedsysassetdoc => {
              if(templates.length === 0){
                    //Actually if the person logged in does NOt have any market tempaltes...They can install the new item WHEREAFTER they
                    //install the relevant tempaltes, for that Newsite.
                    //remember if we don't allow this it means, we let you purchase a tempalte ONCe and use FOREVER which is kind of stinky.
                    this.NewSiteTools(defobj,itemdb)
                }
                else{
                  this.$store.commit('setCustomProcessingDialogText','Checking Assets')
                  let assets = templates.filter(temp => {
                    return temp.Assets
                  }).map(temp => {
                    return temp.Assets
                  }).flat()
                    let totaltemplates = templates.length
                    let templatecounter = 0
                    let totalassets = assets.length
                    let assetcounter = 0
                    if(assetcounter === totalassets){
                    this.ProcessNewSuiteMarketTemplate(defobj,itemdb,masterref,newdocid,templates,totaltemplates,templatecounter)
                    }
                    else{
                      this.$store.commit('setCustomProcessingDialogText','Adding Assets')
                      assets.map(ast => {
                        defastlibref.collection('Assets').doc(ast.id).set(ast).then(newastdoc => {
                            assetcounter++
                            if(assetcounter === totalassets){
                              this.ProcessNewSuiteMarketTemplate(defobj,itemdb,masterref,newdocid,templates,totaltemplates,templatecounter)
                            }
                        })
                      })
                    }
                }
              })
          })
            
        },
        NewSiteTools(defobj,itemdb){
          let vm = this
          this.$store.commit('setCustomProcessingDialogText','Adding Default Tables')
           vm.InstallDefaultEntities(itemdb.collection('Databases').doc(defobj.id)).then(entres => {
              if(entres){
                vm.$store.commit('setCustomProcessingDialogText','Installing Template Plugins')
                let plugins = this.RAPlugins.filter(plug => {
                  return defobj.PluginDataBase && defobj.PluginDataBase[plug.id] && defobj.PluginDataBase[plug.id].Active && plug.Module_Category.Name !== 'Module'
                }).map(plug => {
                  let plugobj = defobj.PluginDataBase[plug.id]
                  return plugobj
                }).concat(this.RAPlugins.filter(plug => {
                  return plug.Module_Category.Name === 'Module'
                }).map(plug => {
                  let plugobj = {
                    id: plug.id,
                    Active: true,
                    Module_Category: plug.Module_Category,
                    Name: plug.Name
                  }
                  return plugobj
                }))
                let totalplugins = plugins.length
                let plugcounter = 0
                plugins.map(plug => {
                  itemdb.collection('systems').doc(defobj.id).collection('plugins').doc(plug.id).set(plug).then(newplug => {
                    plugcounter++
                    if(plugcounter === totalplugins){
                      vm.$store.commit('setCustomProcessingDialogText','Structuring Security')
                      vm.NewSiteUserTools(defobj)
                    }
                  })
                })
                
              }
           })
        },
        NewSiteUserTools(temp){
          let configdb = db.collection('SystemConfig').doc(temp.id)
          let socialdb = db.collection('SocialNetworks').doc(temp.id)
          let buref = configdb.collection('businessunits')
          let HeadofficeObj = {
            Name: 'Head Office',
            Description: 'The default, main Business Unit',
            NameQuery: [
              'h',
              'he',
              'hea',
              'head',
              'heado',
              'headof',
              'headoff',
              'headoffi',
              'headoffic',
              'headoffice'
            ],
            childarrayDBRules: {
              Head_Office: true
            },
            childarrayQuery: ['Head_Office'],
            Top: true
          }
          let GuestObj = {
            Name: 'Guest',
            Description: 'A Business Unit completely unrelated to others meant to give basic Signed In Permissions to any Guest Users',
            NameQuery: [
              'g',
              'gu',
              'gue',
              'gues',
              'guest',
            ],
            childarrayDBRules: {
              Guest: true
            },
            childarrayQuery: ['Guest'],
            IsGuestBU: true,
            FinalStructure: true
          }
          buref.doc('Guest').set(GuestObj)
          buref.doc('Head_Office').set(HeadofficeObj)
        let userref = configdb.collection('users')  
        let userroleref = configdb.collection('userroles')
        let userprops = ['id','Name','Surname','Full_Name','Email','Mobile_Number','Profile_Photo',
        'Gender','Title','AboutMe']
        let defuser = {
          Business_Unit: {Name: HeadofficeObj.Name,id: 'Head_Office'},
          Business_Unitid: 'Head_Office',         
          Created_On: new Date(),
          Subordinatearrayquery: [temp.Ownerid],
          SubordinateArrayDBrules: {[temp.Owner.id]: true},
        }
        userprops.map(prp => {
          if(typeof this.userLoggedIn[prp] !== 'undefined'){
            defuser[prp] = this.userLoggedIn[prp]
          }
        })
        let userroleobj = {
          ...defuser,
          IsFeaturedMember: false,
          IsSiteCreator: false,
          IsWebDeveloper: false,
          MonetizationOfficer: false,
          IsEagleViewer: false,
          BusinessProfileAdmin: false,
          SiteContentAdmin: false,
          IsGuestUser: false,
          IsSystemAdmin: false,
          IsTeamActivityManager: false,
          CanInviteUsers: false,
          InviteGroups: [],
          CanExport: false,
          CanImport: false,
        }
        
        let user = {}
        userprops.map(prp => {
          if(typeof this.userLoggedIn[prp] !== 'undefined'){
            user[prp] = this.userLoggedIn[prp]
          }
        })
        let ownermatch = this.UsersStore.find(obj => obj.id === temp.Ownerid)
          if(!ownermatch){
            ownermatch = user
          }
        let owneruser = {
          id: temp.Ownerid,
          ...temp.Owner,
          ...userroleobj,
          rolesarrayDBRules: {SystemAdmin: true},
          rolesarrayQuery: ['System Admin'],
          IsSystemAdmin: true,        
          Name: ownermatch.Name,
          Surname: ownermatch.Surname,
          Full_Name: ownermatch.Full_Name,
          Email: ownermatch.Email,
          Title: ownermatch.Title,
          Apps: []
          }
          if(ownermatch.Profile_Photo){
                owneruser.Profile_Photo = ownermatch.Profile_Photo
              }
          
          userref.doc(defuser.id).set(defuser).then(ownerusedoc => {
            configdb.collection('featuredmembers').doc(defuser.id).set({
                    Disabled: false,
                  }).then(clientfeatmemdoc => {
                    socialdb.collection('groupmembers').doc(defuser.id).set({
                      Disabled: false,
                    }).then(clientgroupmemdoc => {
                  userroleref.doc(owneruser.id).set(owneruser).then(ownerusedoc => {
                    if(temp.Client && temp.Clientid !== temp.Ownerid){            
                  let clientmatch = this.UsersStore.find(obj => obj.id === temp.Clientid)
                  let clientdefuser = {              
                        Business_Unit: {Name: HeadofficeObj.Name,id: 'Head_Office'},
                        Business_Unitid: 'Head_Office',
                        Subordinatearrayquery: [temp.Clientid],
                        SubordinateArrayDBrules: {[temp.Clientid]: true},
                    }
                    userprops.map(prp => {
                        if(typeof clientmatch[prp] !== 'undefined'){
                          clientdefuser[prp] = clientmatch[prp]
                        }
                      })
                    let clientuser = {
                      ...clientdefuser,
                      ...temp.Client,
                      ...userroleobj,
                      rolesarrayDBRules: {},
                      rolesarrayQuery: [],
                      IsEagleViewer: true,                 
                      Name: clientmatch.Name,
                      Surname: clientmatch.Surname,
                      Full_Name: clientmatch.Full_Name,
                      Email: clientmatch.Email,
                      Title: clientmatch.Title,
                      Apps: []
                      }
                      if(clientmatch.Profile_Photo){
                        clientuser.Profile_Photo = clientmatch.Profile_Photo
                      }
                      userref.doc(clientdefuser.id).set(clientdefuser).then(ownerusedoc => {
                        configdb.collection('featuredmembers').doc(clientdefuser.id).set({
                          Disabled: false,
                        }).then(clientfeatmemdoc => {
                          socialdb.collection('groupmembers').doc(clientdefuser.id).set({
                            Disabled: false,
                          }).then(clientgroupmemdoc => {
                            userroleref.doc(clientuser.id).set(clientuser).then(clientusdoc => {
                              this.$store.commit('setCustomProcessingDialogText','')
                              this.$store.commit('setCustomProcessingDialog',false)
                              this.$store.commit('setActiveSuiteid',temp.id)
                              this.CancelSaveSuiteTemplate()
                            })
                          })
                        })                  
                      })
                    }
                    else{
                        this.$store.commit('setCustomProcessingDialogText','')
                        this.$store.commit('setCustomProcessingDialog',false)
                        this.$store.commit('setActiveSuiteid',temp.id)
                        this.CancelSaveSuiteTemplate()
                    }
                  })
                })
              })
          })
        },
        InstallDefaultEntities(TableCollectionRef){
        let vm = this
        return new Promise(function(resolve, reject) {
          //THE BIG ISSUE HERE IS WE DO NOT HAVE THESE ENTITIES. BUT E HAVE THEM ON ANY SYSTEM. SO...SHOULD BE OKAY?
        let fieldscount = vm.DefaultSystemEntities.map(ent => {
            return ent.AllFields.length
          }).reduce((a, b) => a + b, 0)
          let fieldcounter = 0
          vm.DefaultSystemEntities.map(ent => {
            let entobj = Object.assign({},ent)
            entobj.id = entobj.DisplayName.split(' ').join('_')
            delete entobj.AllFields
            TableCollectionRef.collection('entities').doc(entobj.id).set(entobj).then(newnetobj => {
              ent.AllFields.map(field => {
                TableCollectionRef.collection('entities').doc(entobj.id).collection('AllFields').doc(field.id).set(field).then(newfieldoc => {
                  fieldcounter++
                  if(fieldcounter === fieldscount){
                      resolve('Completed Entities')
                  }
                })
              })
            })

          })
        })
      },
    }
}
</script>

<style>

</style>



